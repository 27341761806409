<template lang="pug">
h1 Cookie Policy
hr
p To make this website work properly, Spell sometimes places small data files called cookies on your device. Most big websites do this too.
p A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences (such as login, language, font size and other display preferences) over a period of time, so you don't have to keep re-entering them whenever you come back to the site or browse from one page to another
h2 How do we use cookies?
p There are cookies that are necessary to allow you to browse our website, use its features, and access secure areas. The use of these particular cookies is essential for Spell to work, and we do not use them to collect your personal information.
p We also use different functional cookies to store information that is necessary for Spell to work properly. The functional cookies we use include:
p Security and authentication cookies that enforce your data protection. For instance, Spell stores a session cookie enabling us to detect that your browser has an authenticated session in Spell. We also use cookies to store a special security token that allows us to protect your account against CSRF (https://en.wikipedia.org/wiki/Cross-site_request_forgery) attacks.
p User interface customization persistent cookies are used to store a user's preference for some display options. Examples include your country or language preference for Spell, or a specific cookie that is set when you click a close icon on a notification in your profile, disabling other such notifications for the duration of your browser session.
p Spell also uses performance cookies to collect information about how you use our website and how often. Because these cookies are not strictly necessary for the use of our website, we require your consent to use them. The performance cookies we use include:
p First party analytics cookies - We use these cookies to estimate the number of unique visitors, to improve our website and to detect the most searched for words in search engines that lead to a webpage.
p We use Google Analytics and other third-party analytics providers to help measure how users interact with our website content.
p Targeting cookies are used on our website to tailor marketing to you and provide you with a more personalized service in the future. These cookies remember that you visited our website and we may share this information with third-parties, such as advertisers.
p Although these cookies can track your visits to our website and other sites, they typically cannot personally identify you.
h2 How to control cookies?
p You can control and/or delete cookies as you wish – for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.
p If you use our website without opting out, it means that you understand and agree to data collection for the purpose of marketing ads to you.
p To opt-out from retargeting campaigns just follow this #[a(href="https://optout.networkadvertising.org/?c=1") link].
p This section supplements the information contained in this Cookie Policy and applies solely to visitors, users, and others who are residents of the State of California, as defined in Section 17014 of Title 18 of the California Code of Regulations. This section is effective as of January 1, 2020, to comply with the #[a(href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180SB1121") California Consumer Privacy Act of 2018 (“CCPA”)].
</template>

<script>
export default {
  // You can specify layout component name here (default value is 'default')
  layout: 'simple'
}
</script>
